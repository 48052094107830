.plone-navbar-nav li {
  &:hover > .submenu {
    display: block;
    visibility: visible;
  }
  &.active:after,
  &:hover:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -10px;
    width: 100%;
    z-index: 999;
  }
  .submenu {
    background-color: @plone-sitenav-bg;
    visibility: hidden;
    list-style: none;
    padding-left: 0;
    padding-bottom: 0;
    position: absolute;
    z-index: 9999;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-width: thin;
    border-style: solid;
    border-color: lighten(@plone-link-color, 20%);
    a {
      color: @plone-text-color;
      display: block;
      line-height: 20px;
      min-width: 14em;
      padding: 15px 10px 15px 12px;
      position: relative;
      &:hover {
        background-color: transparent;
        color: @plone-link-color;
        text-decoration: none;
      }
      &:before {
        margin-right: 10px;
      }
      .submenu_image {
        float: right;
      }
    }

    li {
      position: relative;
      float: left;
      &:hover ul {
        top: 0;
        left: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .plone-navbar-nav li {
    .submenu {
      display: none;
    }
    &:hover > .submenu {
      display: none;
    }
  }
}
