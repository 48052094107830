/* Carousel */

.carousel {
  margin-bottom: @plone-sitenav-margin-bottom;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
}
.banner-img {
  width: 100%;
  height: auto;
}

.carousel-caption {
  display: none;
}
@media (min-width: @plone-grid-float-breakpoint) {
  .carousel-caption {
    display: block;
    height: 60%;
  }
}

.carousel-text-wrap {
  display: block;
  vertical-align: middle;
  align-items: center;
}
