/* our custom styles */
@plone-body-bg: white;
@plone-footer-bg: #fafafa; //almost white
@plone-link-color: #fc421d;
@plone-link-color-on-dark: #fc7a1d;


/* TinyMCE templates */

// image-grid-2x2:
.image-grid-2x2 {
    padding: 0;
    margin: 0 0 @grid-gutter-width 0;
    list-style: none;
    .make-row();

    > li {
        .make-xs-column(12);
        .make-sm-column(6);
        margin-top: @grid-gutter-width;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
