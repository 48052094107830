.news-image-icon {
  width: 100px;
	@media (min-width: @plone-screen-sm-min) { width:150px;}
	@media (min-width: @plone-screen-md-min) { width:200px;}
	@media (min-width: @plone-screen-lg-min) { width:200px;}
  display: inline;
  float: left;
  vertical-align: top;
}

.tile, .news-title {
  font-weight: bold;
}

.portletItemDetails {
  color: @plone-link-color-on-dark;
}
